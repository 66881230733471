<div class="container-fluid">


  <nav class="navbar navbar-dark navbar-expand-lg p-3">

      <img src="assets/images/whitelogo.webp" alt="Logo" style="width: 6rem;height:auto;" class="img-fluid p-2">


    <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">


      <ul class="navbar-nav mt-4 me-auto mb-2 mb-lg-0">
        <li *ngFor="let link of navLinks$ | async" class="nav-item">
          <a [routerLink]="['/', currentLang, link.url]" class="nav-link text-light p-2 fw-semibold fs-5" tabindex="-1" aria-disabled="true">

            {{ currentLang === 'ar' ? link.title.ar : link.title.en }}
          </a>
        </li>
        <li class="nav-item">
          <a class=" nav-link"> <app-translate-button></app-translate-button></a>
          </li>
      </ul>
    </div>
  </nav>
</div>
