<table class="table caption-top">
  <caption>Navbar Links</caption>
  <thead>
    <tr>
      <th scope="col">Fullname</th>
      <th scope="col">Email</th>
      <th scope="col">Message</th>



    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let submission of formSubmissions |async">

      <td>{{ submission.fullname  }}</td>
      <td> {{ submission.email }} </td>
      <td>{{ submission.message }} </td>



    </tr>

  </tbody>
</table>
