import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { Aboutus } from '../models/aboutus';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutusService {

  private collectionName = 'aboutus';

constructor(private fireStore : FirestoreService<Aboutus>) { }


getAllAboutus(): Observable<Aboutus[]>{

  return this.fireStore.getAll(this.collectionName , 'order' , 'asc')
}


}


