import { Component } from '@angular/core';
import { ContactForm } from '../models/contactForm';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SendGridService } from 'src/app/admin/services/sendgrid.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent {

  formInputs: ContactForm = { fullname: '', email: '', phone: '', message: '', timestamp: new Date() };
  isSubmitted: boolean = false;  // New flag to track submission

  constructor(private firestore: AngularFirestore, private sendGridService: SendGridService) { }

  submitForm() {
    // Save the form data to Firebase
    this.firestore.collection('formSubmissions').add(this.formInputs).then(() => {
      console.log('Form submission saved to Firestore');

      // Send email notification to admin
      this.sendGridService.sendEmail(this.formInputs).subscribe(
        (response) => {
          console.log('Email sent successfully', response);
          this.formInputs = { fullname: '', email: '', phone: '', message: '', timestamp: new Date() }; // Clear the form inputs

        setTimeout(this.temp , 3000)


        },
        (error) => {
          console.error('Error sending email', error);
        }
      );
    }).catch((error) => {
      console.error('Error saving form submission', error);
    });
  }

  temp(){
    this.isSubmitted = true
  }
}
