import { Injectable } from '@angular/core';

import { filter } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {

       // Listen for route changes

   this.router.events.pipe(filter(event => event instanceof NavigationEnd))
   .subscribe (()=>{
   const lang = this.route.snapshot.firstChild?.paramMap.get ('lang' ) ||'ar';
   this.translate.use(lang);
  });
  }

setLanguage(lang:string){
  this.translate.use(lang);
  this.router.navigate([`/${lang}${this.router.url.slice(3)}`]); // Replace current URL with new language prefix
}
}
