<app-nav></app-nav>
<app-spinner *ngIf="!(servicesList$ | async)"></app-spinner>
<div class="container mt-5">
  <!-- Header Section -->
  <div class="row position-relative">
      <img src="assets/images/services.webp" class="img-fluid" style="width: 100%; height: auto;" alt="">
      <div class="overlay-text position-absolute top-50 start-50 translate-middle text-white text-center">
          <h1 class="display-4">{{ 'servicespage.title' | translate }}</h1>
          <p class="lead">{{ 'servicespage.description' | translate }}</p>
      </div>
  </div>

  <div class="row">

      <!-- Service: تقنية المعلومات -->
      <div class="col-md-6 col-sm-12 mb-4" *ngFor=" let item of servicesList$ |async ">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">

                  <i class={{item.icon}}></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'|translate}} shadowx">{{currentLang === 'ar' ? item.mainPoint.ar : item.mainPoint.en}}</span>
              </div>
              <div class="card card-lg service-card shadow-sm">
                  <div class="card-body">

                    <ul class="mt-2 list-unstyled">
                      <li class="card-text p-2" *ngFor="let subpoint of item.subpoints">
                        {{ currentLang === 'ar' ? subpoint.ar : subpoint.en }}
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </div>

      <!-- Service: الاستشارات الإدارية -->
      <!-- <div class="col-md-6 col-sm-12 mb-4">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">
                  <i class=""></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'|translate}} shadowx">{{ 'servicespage.service2' | translate }}</span>
              </div>
              <div class="card card-lg service-card shadow-sm">
                  <div class="card-body">

                    <ul>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point5' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point6' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point7' | translate }}
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </div> -->

      <!-- Service: إدارة الجودة -->
      <!-- <div class="col-md-6 col-sm-12 mb-4">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">
                  <i class=""></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'|translate}} shadowx">{{ 'servicespage.service3' | translate }}</span>
              </div>
              <div class="card service-card shadow-sm">
                  <div class="card-body">

                    <ul>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point8' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point9' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point10' | translate }}
                      </li>
                    </ul>

                  </div>
              </div>
          </div>
      </div> -->

      <!-- Service: الحج والعمرة -->
      <!-- <div class="col-md-6 col-sm-12 mb-4">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">
                  <i class=""></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'|translate}} shadowx">{{ 'servicespage.service4' | translate }}</span>
              </div>
              <div class="card service-card shadow-sm">
                  <div class="card-body">
                    <ul>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point11' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point12' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point13' | translate }}
                      </li>
                    </ul>

                  </div>
              </div>
          </div>
      </div> -->

      <!-- Service: الدراسات التعليمية -->
      <!-- <div class="col-md-6 col-sm-12 mb-4">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">
                  <i class="fas fa-book-open"></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'|translate}} shadowx">{{ 'servicespage.service5' | translate }}</span>
              </div>
              <div class="card card-sm service-card shadow-sm">
                  <div class="card-body">

                    <ul>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point14' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point15' | translate }}
                      </li>
                    </ul>

                  </div>
              </div>
          </div>
      </div> -->

      <!-- Service: تطوير وإدارة المشاريع -->
      <!-- <div class="col-md-6 col-sm-12 mb-4">
          <div class="service-icon-wrapper">
              <div class="service-icon mb-3">
                  <i class="fas fa-cogs"></i>
                  <span class="fs-2 me-3 {{'me-3.me-3'| translate}} shadowx">{{ 'servicespage.service6' | translate }}</span>
              </div>
              <div class="card card-sm service-card shadow-sm">
                  <div class="card-body">

                    <ul>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point16' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point17' | translate }}
                      </li>
                      <li class="card-text p-2">
                        {{ 'servicespage.points.point18' | translate }}
                      </li>
                    </ul>

                  </div>
              </div>
          </div>
      </div> -->

  </div>
</div>

<app-footer></app-footer>
