import { Component, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators'; // Import finalize operator
import { Hero } from '../../models/hero';
import { HeroService } from '../../services/hero.service';
import { AngularFireStorage } from '@angular/fire/compat/storage'; // Firebase Storage
import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-hero-managment',
  templateUrl: './hero-managment.component.html',
  styleUrls: ['./hero-managment.component.css']
})
export class HeroManagmentComponent  {


  private heroListSubscription!: Subscription;
  heros: Hero[] = []; // Store all heroes here
  paginatedHeros: Hero[] = [];
  totalHeros: number = 0;
  pageSize: number = 2;
  currentPage: number = 0;
  totalPages: number = 0;
  HeroPages$!: Observable<Hero[]>; // List of hero pages
  hero: Hero = { id: '', heading: {ar:'', en:''}, subheading: {ar:'', en:''}, imageUrl: '', order: '' }; // Form
  selectedFile: File | null = null; // Store selected file
  private  collectionName = 'hero'
  constructor(
    private fireStoreService: FirestoreService<Hero> ,
    private storage: AngularFireStorage, // Inject Firebase Storage
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getAllHero();
    this.renderer.setStyle(document.body, 'background-color', 'rgb(155, 171, 146)');
  }


  ngOnDestroy(): void {
    // Unsubscribe from observable to avoid memory leaks
    if (this.heroListSubscription) {
      this.heroListSubscription.unsubscribe();
    }
  }
  getAllHero() {
    this.fireStoreService.getAll(this.collectionName, 'order', 'asc').subscribe((heros: Hero[]) => {
      this.heros = heros; // Store all heroes
      this.totalHeros = heros.length;
      this.totalPages = Math.ceil(this.totalHeros / this.pageSize);
      this.paginateHeros(heros);
    });
  }


  // Handle file selection
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]; // Store selected file
  }

  // Upload image to Firebase
  uploadImage() {
    if (this.selectedFile) {
      const filePath = `heroes/${this.selectedFile.name}`; // Define the file path
      const fileRef = this.storage.ref(filePath); // Reference to the file
      const task = this.storage.upload(filePath, this.selectedFile); // Upload task

      console.log('Upload task started');

      // Once upload is done, get the URL of the file
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe({
            next: (url) => {
              this.hero.imageUrl = url; // Set image URL to hero object
              console.log('Image uploaded successfully! URL:', url);
            },
            error: (err) => {
              console.error('Error getting download URL:', err);
            }
          });
        })
      ).subscribe();
    }
  }

  // Save the hero data
  saveHero() {
    this.fireStoreService.save(  this.collectionName ,this.hero , this.hero.id ,)
      .then(() => {
        console.log("Hero Added");
        this.clearForm();
      }, err => {
        console.log(err, 'error');
      });
  }

  // Clear the form
  clearForm() {
    this.hero = { id: '', heading: {ar:'', en:''}, subheading: {ar:'', en:''}, imageUrl: '', order: '' };
    this.selectedFile = null;
  }

  editHero(hero: Hero) {
    this.hero = { ...hero };
  }

  deleteHero(id: string) {
    this.fireStoreService.delete(id , this.collectionName)
    console.log("Hero deleted");
  }



  paginateHeros(heros: Hero[]) {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedHeros = heros.slice(startIndex, endIndex); // Paginated result
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.paginateHeros(this.heros); // Use existing heroes array
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.paginateHeros(this.heros); // Use existing heroes array
    }
  }


  }



