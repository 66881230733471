import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerImg } from 'src/app/admin/models/customerImg';
import { OurClientsService } from 'src/app/admin/services/our-clients.service';

declare var Flickity:any;

@Component({
  selector: 'app-testmonials',
  templateUrl: './testmonials.component.html',
  styleUrls: ['./testmonials.component.css']
})
export class TestmonialsComponent implements OnInit , AfterViewInit{
  private flickityInstance:any;
  clientsLogo$!:Observable<CustomerImg[]>
  private startingImageIndex: number = 2;
  constructor( private ourClientService:OurClientsService) { }

  ngOnInit(): void  {

    this.clientsLogo$ = this.ourClientService.getAllClientsLogo();

    }

    ngAfterViewInit(): void {
      // Initialize Flickity after a short delay to allow *ngFor to finish rendering
      this.clientsLogo$.subscribe((CustomerImgs) => {
        setTimeout(() => {
          this.reinitializeFlickity(CustomerImgs);
        }, 0); // You can adjust the timeout duration if needed
      });
    }


    reinitializeFlickity(logos: CustomerImg[]) {
      // Check if Flickity has been initialized already and destroy it
      if (this.flickityInstance) {
        this.flickityInstance.destroy(); // Destroy existing Flickity instance to avoid duplication
      }

      setTimeout(() => {
        this.initializeFlickity(); // Reinitialize Flickity after items have been updated/rendered
      }, 0);

    }

    initializeFlickity(){

        this.flickityInstance = new Flickity('.carousel',{
          "groupCells": true, "wrapAround": true, "autoPlay": 1700
        } )


      this.flickityInstance.select(this.startingImageIndex);

    }



  }
