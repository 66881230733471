import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from '../Home-page/components/home/home.component';
import { CommonModule } from '@angular/common';
import { NavComponent } from 'src/Home-page/components/shared-Components/nav/nav.component';
import { HeroComponent } from 'src/Home-page/components/hero/hero.component';
import { AboutUsComponent } from 'src/Home-page/components/about-us/about-us.component';
import { TranslateButtonComponent } from 'src/Home-page/components/shared-Components/translate-button/translate-button.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OurServicesComponent } from 'src/Home-page/components/our-services/our-services.component';
import { ContactusComponent } from 'src/Home-page/components/contactus/contactus.component';
import { TestmonialsComponent } from 'src/Home-page/components/testmonials/testmonials.component';
import { FooterComponent } from 'src/Home-page/components/footer/footer.component';
import { AboutsUspageComponent } from 'src/app/AboutUs-page/aboutsUspage/aboutsUspage.component';
import { ServicepageComponent } from 'src/Service-page/servicepage/servicepage.component';
import { SpinnerComponent } from 'src/Home-page/components/shared-Components/spinner/spinner.component';
import { ContactComponent } from 'src/ContactUs-page/contact/contact.component';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';







export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    HeroComponent,
    AboutUsComponent,
    TranslateButtonComponent,
    OurServicesComponent,
    TestmonialsComponent,
    ContactusComponent,
    FooterComponent,
    AboutsUspageComponent,
    ServicepageComponent,
    SpinnerComponent,
    ContactComponent,


   ],
  imports: [
    RouterModule,
    AdminRoutingModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,  // Using the factory function here
        deps: [HttpClient]  // Specifying the dependencies for the factory function
      }
    }),
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
