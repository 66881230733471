
<app-nav></app-nav>
<!-- <app-spinner></app-spinner> -->

<app-hero></app-hero>
<app-about-us></app-about-us>
 <app-our-services></app-our-services>
<app-testmonials></app-testmonials>
<app-contactus></app-contactus>
<app-footer></app-footer>
