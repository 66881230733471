import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreService } from 'src/app/core/firestore.service';
import { Hero } from '../models/hero';

@Injectable({
  providedIn: 'root'
})
export class HeroService {

  private collectionName = 'hero';


constructor( private firestoreService:FirestoreService <Hero>) { }

getHero(){

  return this.firestoreService.getAll(this.collectionName , 'order' , 'asc');
}

// updateHeroImageUrl(id: string, imageUrl: Hero) {
//   return this.firestoreService.update(this.collectionName, id, imageUrl)
// }


}
