
<div class="container">
  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="saveService()" #servicengForm="ngForm">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="serviceFormOrder" class="form-label">Order:</label>
            <input type="text" class="form-control" id="serviceFormOrder" [(ngModel)]="serviceForm.order" name="order" required />
          </div>
          <div class="col-md-3 mb-3">
            <label for="MainPointAr" class="form-label">MainPoint (AR):</label>
            <input type="text" class="form-control" id="MainPointAr" [(ngModel)]="serviceForm.mainPoint.ar" name="mainPointAr"  />
          </div>
          <div class="col-md-3 mb-3">
            <label for="MainPointEn" class="form-label">MainPoint (EN):</label>
            <input type="text" class="form-control" id="MainPointEn" [(ngModel)]="serviceForm.mainPoint.en" name="mainPointEn"  />
          </div>

          <div *ngFor="let subpoint of serviceForm.subpoints; let i = index" class="col-md-6 mb-3">
            <label [for]="'subheadingEn' + i" class="form-label">Subheading {{i + 1}} (EN):</label>
            <input type="text" class="form-control" [id]="'subheadingEn' + i" [(ngModel)]="subpoint.en" [name]="'subheadingEn' + i" />

            <label [for]="'subheadingAr' + i" class="form-label">Subheading {{i + 1}} (AR):</label>
            <input type="text" class="form-control" [id]="'subheadingAr' + i" [(ngModel)]="subpoint.ar" [name]="'subheadingAr' + i" />
          </div>

          <div class="col-md-3 mb-3">
            <label for="heroId" class="form-label">ID:</label>
            <input type="text" class="form-control" id="heroId" [(ngModel)]="serviceForm.id" name="id" readonly />
          </div>
        </div>
        <button type="submit" class="btn btn-success" [disabled]="!servicengForm.valid">Add</button>
      </form>
    </div>
  </div>
</div>





<table class="table caption-top">
  <caption>Service Page section</caption>
  <thead>
    <tr>
      <th scope="col">Order</th>
      <th scope="col">Main Point (EN)</th>
      <th scope="col">Main Point (AR)</th>
      <th scope="col">Subpoints (EN)</th>
      <th scope="col">Subpoints (AR)</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let serviceItems of paginatedServices">
      <td>{{ serviceItems.order }}</td>
      <td>{{ serviceItems.mainPoint.en }}</td>
      <td>{{ serviceItems.mainPoint.ar }}</td>
      <td>
        <ul>
          <li *ngFor="let subpoint of serviceItems.subpoints">{{ subpoint.en }}</li>
        </ul>
      </td>
      <td>
        <ul>
          <li *ngFor="let subpoint of serviceItems.subpoints">{{ subpoint.ar }}</li>
        </ul>
      </td>
      <td>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-sm btn-warning" (click)="editService(serviceItems)">
            <i class="fa-solid fa-pen" style="color: #fdfdfd;"></i>
          </button>
          <button type="button" class="btn btn-sm btn-danger" (click)="deleteService(serviceItems.id)">
            <i class="fa-solid fa-trash" style="color: #fafafa;"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="row justify-content-center">

  <div class="col-md-4">
<!-- Pagination Controls -->
<div class="pagination-controls">
  <button class="btn btn-sm btn-light" (click)="previousPage()" [disabled]="currentPage === 0"><</button>
  <span class="mx-5">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
  <button class="btn btn-sm btn-light" (click)="nextPage()" [disabled]="currentPage >= totalPages - 1"> ></button>
</div>
  </div>
</div>
