<app-nav></app-nav>

<app-spinner></app-spinner>
<div class="container">

  <div class="row position-relative">
    <img src="assets/images/team2.webp"   class="img-fluid " style="width: 100% ; height:auto" alt="" loading="lazy" >
    <div class="overlay-text position-absolute top-50 start-50 translate-middle text-white text-center">
        <h1 class="display-4">{{ 'aboutuspage.aboutuspage_heading' | translate}}</h1>
        <p class="lead">{{ 'aboutuspage.aboutuspage_heading1' | translate}}</p>
    </div>
</div>

    <div class="row" *ngFor="let aboutusItem of aboutusPage$ |async ">

      <div class="col-lg-12 col-sm-10 col-md-12">

        <p class="fs-5 fw-semibold mt-5 text-light p-4">{{ aboutusItem.description | translate }} </p>

        <h1 class="text-center mt-5 fw-bold fs-1 ">
          <span class="border-3 pb-2 ">{{ 'aboutuspage.vision_heading' | translate }} </span>
        </h1>
        <p class="text-light fs-5 mt-5 p-4">{{ aboutusItem.vision | translate }}
        </p>

        <h1 class="text-center mt-5 fw-bold fs-1 "> <span class="border-3 pb-2 "> {{ 'aboutuspage.mission' | translate }} </span> </h1>

        <p class="text-light fs-5 mt-5 p-4">
          {{aboutusItem.mission + '.point1'| translate}} <br />
          {{aboutusItem.mission + '.point2'| translate}} <br />
          {{aboutusItem.mission + '.point3'| translate}} <br />
          {{aboutusItem.mission + '.point4'| translate}} <br />
          {{aboutusItem.mission + '.point5'| translate}} <br />

          {{aboutusItem.mission + '.point6  '| translate}}
        </p>

        <h1 class="text-center mt-5 fw-bold fs-1 "> <span class="border-3 pb-2 "> {{ 'aboutuspage.founder' | translate }}</span> </h1>

      </div>
    </div>




</div>


<app-footer></app-footer>
