<div class="container">

  <div class="row mt-5 pt-5">

    <div class="section-title text-center mb-30 ">
      <h3 class="text-light fw-light">
        <span class="border bg-orange rounded-pill p-3"> {{'testmonials.heading1' | translate}} </span>
      </h3>

      <h4 class="text-light fw-light mt-5">
        {{'testmonials.heading2' | translate}}
      </h4>
    </div>
  </div>


  <!-- Flickity HTML init -->
  <div class="carousel">
    <div class="carousel-cell" *ngFor=" let logo of clientsLogo$ |async; let last = last">
      <div class="card mt-5" style="width: 100%;height: auto; background-color: rgb(250, 250, 250); " >
      <div class="card-body text-light justify-content-center">
        <img [src]="logo.imageUrl" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div>
  </div>


    <!-- <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%; height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/client2.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>
    <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%; height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/client3.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>
    <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%;  height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/client4.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>

    <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%;  height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/client6.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>


    <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%;  height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/euro.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>

    <div class="carousel-cell">
      <div class="card mt-5" style="width: 100%;  height: auto; background-color: rgb(250, 250, 250); ">
      <div class="card-body text-light justify-content-center">
        <img src="assets/images/kidana-logo-gold-06.webp" class="  " style="width:100%; height:12rem" alt="..." loading="lazy" >



      </div>
    </div></div>
  </div> -->


</div>
