

<div class="row">

  <div class="col-lg-3 mt-5"></div>
  <div class="col-lg-6 mt-5">

<button class="btn-danger btn btn-sm text-center" (click)="logOut()">  <i class="fa fa-sign-out-alt me-1"> </i>Logout
</button>

</div>
</div>
