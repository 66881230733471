import { Component, OnInit, Renderer2 } from '@angular/core';
import { finalize, Observable, Subscription } from 'rxjs';
import { CustomerImg } from '../models/customerImg';
import { FirestoreService } from 'src/app/core/firestore.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-our-customers',
  templateUrl: './our-customers.component.html',
  styleUrls: ['./our-customers.component.css']
})
export class ourCustomersComponent implements OnInit {

  paginatedLogos: CustomerImg[] = [];
  totalLogos: number = 0;
  pageSize: number = 3;
  currentPage: number = 0;
  totalPages: number = 0;
  private customersImgSubscription!: Subscription;

  customersImg$!: Observable<CustomerImg[]>; // List of customer images
  customersImg: CustomerImg = { id: '', imageUrl: '', order: '' }; // Form
  selectedFile: File | null = null; // Store selected file
  private  collectionName = 'customerImg'
  constructor(
    private fireStoreService: FirestoreService<CustomerImg> ,
    private storage: AngularFireStorage, // Inject Firebase Storage
    private renderer: Renderer2
  ) {}


  ngOnInit() {
    this.renderer.setStyle( document.body, 'background-color', 'rgb(155, 171, 146)');
    this.getCustomersImg();
  }

  ngOnDestroy() {
    if (this.customersImgSubscription) {
      this.customersImgSubscription.unsubscribe();
    }
  }

  getCustomersImg() {
    this.customersImg$ = this.fireStoreService.getAll(this.collectionName);
    this.customersImgSubscription = this.customersImg$.subscribe((customerImgs: CustomerImg[]) => {
      this.totalLogos = customerImgs.length;
      this.totalPages = Math.ceil(this.totalLogos / this.pageSize);
      this.paginateLogo(customerImgs);
    });
  }

  paginateLogo(customerImgs: CustomerImg[]) {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedLogos = customerImgs.slice(startIndex, endIndex);
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.getCustomersImg(); // Fetch and paginate fresh list
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.getCustomersImg(); // Fetch and paginate fresh list
    }
  }
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0]; // Store selected file
  }

    // Upload image to Firebase
    uploadImage() {
      if (this.selectedFile) {
        const filePath = `customersImg/${this.selectedFile.name}`; // Define the file path
        const fileRef = this.storage.ref(filePath); // Reference to the file
        const task = this.storage.upload(filePath, this.selectedFile); // Upload task

        console.log('Upload task started');

        // Once upload is done, get the URL of the file
        task.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe({
              next: (url) => {
                this.customersImg.imageUrl = url; // Set image URL to logo object
                console.log('Image uploaded successfully! URL:', url);
              },
              error: (err) => {
                console.error('Error getting download URL:', err);
              }
            });
          })
        ).subscribe();
      }
    }



    // Save the logo data
    SaveCustomersImg() {
      this.fireStoreService.save(this.collectionName, this.customersImg, this.customersImg.id)
      .then(() => {
        console.log("Customer image updated successfully");
        this.clearForm();
      })
      .catch((err) => {
        console.log("Error updating customer image:", err);
      });
    }

      // Clear the form
  clearForm() {
    this.customersImg = { id: '', imageUrl: '', order: '' };
    this.selectedFile = null;
  }

  editLogo(customersImg: CustomerImg) {
    this.customersImg = { ...customersImg };
    this.selectedFile = null; // Clear file selection
  }

  // Delete client logo
  deleteLogo(id: string) {
    this.fireStoreService.delete(id, this.collectionName)
      .then(() => {
        console.log("Customer image deleted successfully");
        this.getCustomersImg(); // Refresh the list after deletion
      })
      .catch((err) => {
        console.log("Error deleting customer image:", err);
      });
  }
}
