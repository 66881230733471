import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirestoreEntity } from '../admin/models/FirestoreEntity';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService<T extends FirestoreEntity > {

constructor( private firestore :AngularFirestore) { }

// creating and updating a document.

save( collectionName:string, item:T, id?:string){
  const collection: AngularFirestoreCollection<T> = this.firestore.collection<T>(collectionName);
  if(id){
    return collection.doc(id).set(item, {merge:true});
  }else {
    return collection.add(item);
  }
}

getAll(collectionName:string, order?:string , orderDirection: 'asc' | 'desc' = 'asc' ): Observable<T[]> {

  let collection: AngularFirestoreCollection<T>;
   if(order)
   {
    collection = this.firestore.collection<T>(collectionName, ref => ref.orderBy(order, orderDirection));
   }else{
    collection = this.firestore.collection<T>(collectionName);

   }
   return collection.valueChanges({idField:'id'});


}

update (id:string , collectionName:string ,data:Partial<T>) {

  return this.firestore.collection<T>(collectionName).doc(id).update(data);
}

delete (id:string , collectionName:string){

  return this.firestore.collection<T>(collectionName).doc(id).delete();
}

}
