import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { CustomerImg } from '../models/customerImg';

@Injectable({
  providedIn: 'root'
})
export class OurClientsService {

  private collectionName = 'customerImg';

constructor( private firestoreService: FirestoreService<CustomerImg>) { }

getAllClientsLogo() {

  return this.firestoreService.getAll(this.collectionName, 'order' ,'asc') ;

}

}
