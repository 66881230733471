import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Nav } from 'src/app/admin/models/nav';
import { NavServiceService } from 'src/app/admin/services/NavService.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  currentLang!: string;
  langChangeSubscription!: Subscription;
  navLinks$!: Observable<Nav[]>; // List of nav links
  constructor (private route: ActivatedRoute , private navService: NavServiceService , private translate: TranslateService) {}

  ngOnInit(): void {
    this.navLinks$ = this.navService.getNavLinks();

    this.route.params.subscribe(params => {
      this.currentLang = params['lang'] || 'en'; // Default to 'en' if no lang parameter is present
    });


        // Set initial language
        this.currentLang = this.translate.currentLang || 'ar';

        // Subscribe to language change events
        this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.currentLang = event.lang;
        });
  }



  ngOnDestroy(): void {
    // Unsubscribe from the language change event when the component is destroyed
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
