import { Component, OnInit, Renderer2 } from '@angular/core';
import { SendGridService } from '../services/sendgrid.service'; // Adjust the import path as necessary

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  unreadCount: number = 0; // Variable to hold the unread count

  constructor(private renderer: Renderer2, private sendGridService: SendGridService) { }

  ngOnInit() {
    // Listen for unread count updates
    this.sendGridService.unreadCount$.subscribe((count: number) => {
      this.unreadCount = count; // Update unread count from the service
    });


  }
}
