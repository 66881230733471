
<app-nav></app-nav>

<app-spinner></app-spinner>
<div class="container ">

    <!-- Header Section -->
    <div class="row position-relative">
      <img src="assets/images/contact.webp" class="img-fluid" style="width: 100%; height:auto" alt="" loading="lazy" >
      <div class="overlay-text position-absolute top-50 start-50 translate-middle text-white text-center">
          <h1 class="display-4">{{ 'contactUs.contactUs' | translate}}</h1>
          <p class="lead">{{ 'contactUs.heading2' | translate}}</p>
      </div>
  </div>

  <!-- Contact 1 - Bootstrap Brain Component -->

  <div class="row justify-content-center pt-3 mt-5">
    <div class="col-lg-3 mt-5">

      <h3 class="text-center text-light fw-light "> <span class="border bg-orange rounded-pill p-3">{{ 'contactUs.contactUs' | translate}}</span> </h3>

    </div>

  </div>

  <div class="row justify-content-center mt-5 mb-2">
    <div class="col-lg-6 ">

      <h4 class="text-center text-light p-2 "> {{ 'contactUs.heading' | translate}}</h4>
    </div>

  </div>


  </div>

  <div class="container">
    <div class="row justify-content-lg-center">
      <div class="col-12 col-lg-9">
        <div class="bg-dark border  rounded shadow-sm overflow-hidden">

          <form action="#!">
            <div class="row gy-4 gy-xl-5 p-4 p-xl-5">
              <div class="col-12">
                <label for="fullname" class="form-label">{{'contactUs.fullname' | translate}} <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="fullname" name="fullname" value="" required>
              </div>
              <div class="col-12 col-md-6">
                <label for="email" class="form-label">{{'contactUs.email' | translate}} <span class="text-danger">*</span></label>
                <div class="input-group">

                  <input type="email" class="form-control" id="email" name="email" value="" required>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label for="phone" class="form-label">{{'contactUs.phone' | translate}}</label>
                <div class="input-group">

                  <input type="tel" class="form-control" id="phone" name="phone" value="">
                </div>
              </div>

              <div class="col-12">
                <label for="message" class="form-label">{{'contactUs.message' | translate}} <span class="text-danger">*</span></label>
                <textarea class="form-control" id="message" name="message" rows="3" required></textarea>
              </div>

              <div class="col-4">
                <div class="row justify-content-end">
                  <button class="btn btn-dark btn-lg" type="submit">{{'contactUs.submit' | translate}}</button>
                </div>
              </div>

            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

