<div class="container">
  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="saveAboutUsPage()">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="Description" class="form-label">Description:</label>
            <input type="text" class="form-control" id="Description" [(ngModel)]="aboutusPage.description" name="description"  />
          </div>

          <div class="col-md-3 mb-3">
            <label for="Mission" class="form-label">Mission:</label>
            <input type="text" class="form-control" id="v" [(ngModel)]="aboutusPage.mission" name="mission"  />
          </div>
          <div class="col-md-3 mb-3">
            <label for="Vision" class="form-label">Vision:</label>
            <input type="text" class="form-control" id="Vision" [(ngModel)]="aboutusPage.vision" name="vision"  />
          </div>

          <div class="col-md-3 mb-3">
            <label for="heroId" class="form-label">Id:</label>
            <input type="text" class="form-control" id="heroId" [(ngModel)]="aboutusPage.id" name="id" readonly />
          </div>
        </div>

        <button type="submit" class="btn btn-success">Add</button>
      </form>
    </div>
  </div>
</div>

<table class="table caption-top">
  <caption>About Us section</caption>
  <thead>
    <tr>
      <!-- <th scope="col">Order</th> -->
      <th scope="col">Description</th>
      <th scope="col">Mission</th>
      <th scope="col">Vision</th>

      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let aboutusLink of aboutusPage$ | async">
      <!-- <td>{{ aboutusLink.order }}</td> -->
      <td>{{ aboutusLink.description }}</td>
      <td>{{ aboutusLink.mission }}</td>
      <td>{{ aboutusLink.vision }}</td>


      <td>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-warning" (click)="editAboutUsPage(aboutusLink)">Edit</button>
          <button type="button" class="btn btn-danger" (click)="deleteAboutUsPage(aboutusLink.id)">Delete</button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
