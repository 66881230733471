import { Component, Renderer2 } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email!: string;
  password!: string;

  constructor(private authService: AuthService , private renderer:Renderer2) { }

  onSubmit(){
    this.authService.login(this.email, this.password);

  }



  ngOnInit() {
    // Set the background color when the admin page is loaded
    this.renderer.setStyle(document.body, 'background-color', ' rgb(155, 171, 146)');
  }

  ngOnDestroy() {
    // Reset the background color when leaving the admin page
    this.renderer.setStyle(document.body, 'background-color', '');
  }
}
