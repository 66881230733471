import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavManagementComponent } from './nav-management/nav-management.component';
import { FooterManagementComponent } from './footer-management/footer-management.component';
import { ServicesManagementComponent } from './services-management/services-management.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth-guard/auth.guard';

import { HeroManagmentComponent } from './home-management/hero-managment/hero-managment.component';
import { AboutusComponent } from './home-management/aboutus/aboutus.component';
import { ServicesComponent } from './home-management/Our-services/services.component';
import { InboxComponent } from './inbox/inbox.component';
import { ourCustomersComponent } from './our-customers/our-customers.component';
import { AboutusManagementComponent } from './aboutus-management/aboutus-management.component';

const routes: Routes = [
  // Login route
  { path: 'login', component: LoginComponent },

  // Admin routes with authentication guard
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [

      { path: 'inbox', component: InboxComponent },
      { path: 'hero-management', component: HeroManagmentComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'nav-management', component: NavManagementComponent },
      { path: 'footer-management', component: FooterManagementComponent },
      { path: 'our-customers', component: ourCustomersComponent },
      { path: 'services-management', component: ServicesManagementComponent },
      { path: 'aboutus-management', component: AboutusManagementComponent },
      { path: '', redirectTo: 'inbox', pathMatch: 'full' }, // Redirect to default child route
    ],
  },

  // Default route (redirect to login page if no path matches)
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
