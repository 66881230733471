import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-management',
  templateUrl: './footer-management.component.html',
  styleUrls: ['./footer-management.component.css']
})
export class FooterManagementComponent {

}
