import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { AboutusPage } from '../models/aboutusPage';

@Injectable({
  providedIn: 'root'
})
export class AboutusPageService {

constructor( private firestore:FirestoreService<AboutusPage>) { }

private collectionName = 'aboutusPage';

getAboutusPage() {

 return this.firestore.getAll(this.collectionName);
}

}
