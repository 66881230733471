<div class="container">
  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="saveHero()">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="HeroOrder" class="form-label">Order:</label>
            <input type="text" class="form-control" id="HeroOrder" [(ngModel)]="hero.order" name="order" />
            </div>

            <div class="col-md-3 mb-3">
              <label for="HeadingAr" class="form-label">Heading (AR):</label>
              <input type="text" class="form-control" id="HeadingAr" [(ngModel)]="hero.heading.ar" name="headingAr" required />
            </div>
            <div class="col-md-3 mb-3">
              <label for="HeadingEn" class="form-label">Heading (EN):</label>
              <input type="text" class="form-control" id="HeadingEn" [(ngModel)]="hero.heading.en" name="headingEn" required />
            </div>
            <div class="col-md-3 mb-3">
              <label for="SubheadingAr" class="form-label">Subheading (AR):</label>
              <input type="text" class="form-control" id="SubheadingAr" [(ngModel)]="hero.subheading.ar" name="subheadingAr" required />
            </div>



          <div class="col-md-3 mb-3">
            <label for="SubheadingEn" class="form-label">Subheading (EN):</label>
            <input type="text" class="form-control" id="SubheadingEn" [(ngModel)]="hero.subheading.en" name="subheadingEn" required />
          </div>

          <div class="col-md-3 mb-3">
            <label for="Image" class="form-label">Image:</label>
            <input type="file" class="form-control" id="Image" (change)="onFileSelected($event)" />
            <button type="button" class="btn btn-primary mt-2" (click)="uploadImage()">Upload Image</button>
          </div>

          <div class="col-md-3 mb-3">
            <label for="heroId" class="form-label">Id:</label>
            <input type="text" class="form-control" id="heroId" [(ngModel)]="hero.id" name="id" readonly />
          </div>
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!selectedFile && !hero.order">Add</button>
      </form>
    </div>
  </div>
</div>

<table class="table caption-top">
  <caption>Hero Pages</caption>
  <thead>
    <tr>
      <th scope="col">Order</th>
      <th scope="col">Heading(AR):</th>
      <th scope="col">Heading(EN):</th>
      <th scope="col">Subheading(AR)</th>
      <th scope="col">Subheading(EN)</th>
      <th scope="col">Image</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let hero of paginatedHeros">
      <td>{{ hero.order }}</td>
      <td>{{ hero.heading.ar }}</td>
      <td>{{ hero.heading.en }}</td>
      <td>{{ hero.subheading.ar }}</td>
      <td>{{ hero.subheading.en }}</td>
      <td>
        <img [src]="hero.imageUrl" alt="Hero Image" style="width: 100px; height: auto;" *ngIf="hero.imageUrl"/>
      </td>
      <td>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-sm btn-warning" (click)="editHero(hero)"><i class="fa-solid fa-pen" style="color: #fdfdfd;"></i></button>
        <button type="button" class="btn btn-sm  btn-danger" (click)="deleteHero(hero.id)"><i class="fa-solid fa-trash" style="color: #fafafa;"></i></button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="row justify-content-center">

  <div class="col-md-4">
<!-- Pagination Controls -->
<div class="pagination-controls">
  <button class="btn btn-sm btn-light" (click)="previousPage()" [disabled]="currentPage === 0"><</button>
  <span class="mx-5">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
  <button class="btn btn-sm btn-light" (click)="nextPage()" [disabled]="currentPage >= totalPages - 1"> ></button>
</div>
  </div>
</div>
