// sendgrid.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; // Assuming you have environment variables
import { ContactForm } from 'src/Home-page/components/models/contactForm';

// Service for sending emails using SendGrid API.
@Injectable({
  providedIn: 'root',
})
export class SendGridService {
  private sendGridAPI = environment.production
    ? `${environment.apiBaseURL}/.netlify/functions/send-email`
    : 'http://localhost:8888/.netlify/functions/send-email'; // Use this in development when running locally
  unreadCount$: any;
  unread: any;

  constructor(private http: HttpClient) {}

  sendEmail(formInputs: any): Observable<any> {
    return this.http.post(this.sendGridAPI, formInputs);
  }
}
