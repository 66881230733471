 <div class="container">

  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="saveNavLink()">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="PageTitleAr" class="form-label">Title (AR):</label>
            <input type="text" class="form-control" id="PageTitleAr" [(ngModel)]="navLink.title.ar" name="titleAr" required />
          </div>

          <div class="col-md-3 mb-3">
            <label for="PageTitleEn" class="form-label">Title (EN):</label>
            <input type="text" class="form-control" id="PageTitleEn" [(ngModel)]="navLink.title.en" name="titleEn" required />
          </div>


          <div class="col-md-3 mb-3">
            <label for="PageUrl" class="form-label">URL:</label>
            <input type="text" class="form-control" id="PageUrl" [(ngModel)]="navLink.url" name="url" required />
          </div>
          <div class="col-md-3 mb-3">
            <label for="PageOrder" class="form-label">Order:</label>
            <input type="text" class="form-control" id="PageOrder" [(ngModel)]="navLink.order" name="order" />
          </div>
          <div class="col-md-3 mb-3">
            <label for="PageId" class="form-label">Id:</label>
            <input type="text" class="form-control" id="PageId" [(ngModel)]="navLink.id" name="id" readonly />
          </div>
        </div>
        <button type="submit" class="btn btn-success">Add</button>
      </form>
    </div>
  </div>


</div>

<table class="table caption-top">
  <caption>Navbar Links</caption>
  <thead>
    <tr>
      <th scope="col">Order</th>
      <th scope="col">Title (AR)</th>
      <th scope="col">Title (EN)</th>
      <th scope="col">Url</th>


      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let link of navLinks$ | async">

      <td>{{ link.order }}</td>
      <td> {{ link.title.ar }} </td>
      <td> {{ link.title.en }} </td>
      <td>{{ link.url }} </td>


      <td>
        <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-sm btn-warning" (click)="editNavLink(link)"><i class="fa-solid fa-pen" style="color: #fdfdfd;"></i></button>
        <button type="button" class="btn btn-sm  btn-danger" (click)="deleteNavLink(link.id)"><i class="fa-solid fa-trash" style="color: #fafafa;"></i></button>

      </div>
    </td>
    </tr>

  </tbody>
</table>


