import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirestoreService } from 'src/app/core/firestore.service';
import { Nav } from '../models/nav';

@Injectable({
  providedIn: 'root'
})
export class NavServiceService {

  private collectionName = 'navbar';

constructor( private firestoreService: FirestoreService<Nav>) { }

 // Create a new nav item
// addNavLink(nav: Nav): Promise<void> | Promise<any> {

// return this.firestoreService.save(this.collectionName ,nav , nav.id)
// }

 // get all nav item

 getNavLinks(): Observable<Nav[]> {
  return this.firestoreService.getAll(this.collectionName , 'order' , 'asc');

 }

//  updateNavLink (id: string, nav: Nav){
//   return this.firestoreService.update(this.collectionName , id, nav)
//  }

//  deleteNavLink(id: string){
//   return this.firestoreService.delete(id , this.collectionName);
//  }
}
