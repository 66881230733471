import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslationService } from './services/translation.service';

@Component({
  selector: 'app-translate-button',
  templateUrl: './translate-button.component.html',
  styleUrls: ['./translate-button.component.css']
})
export class TranslateButtonComponent implements OnInit {
  constructor(
    private translate : TranslationService,
    private route: ActivatedRoute,

  ) {}



  ngOnInit() {
    this.route.params.subscribe(params => {
      const lang = params['lang'] || 'ar'; // Default to 'ar' if no lang param
      this.translate.setLanguage(lang);
      this.updateDirection(lang);
    });
  }

  switchLanguage(lang: string) {
    this.translate.setLanguage(lang);
    this.updateDirection(lang);
  }

  updateDirection(lang: string) {
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
  }
}
