
 <div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- Flickity HTML init -->
      <div class="carouselx">
        <div class="carousel-cell" *ngFor="let hero of hero$ | async; let last = last">
          <img class="img-fluid carousel-img" [src]="hero.imageUrl" alt="alternative">
          <div class="overlay d-flex align-items-center justify-content-center">
            <div class="col-md-8 col-lg-7 col-sm-8">
              <h1 class="fw-bold overlay-text fs-1 text-light mt-3 slide-left">{{ currentLang === 'ar'? hero.heading.ar:hero.heading.en }}</h1>
              <p class="overlay-text mt-5 fs-2 fw-bold slide-left">
                {{ currentLang === 'ar'? hero.subheading.ar:hero.subheading.en }}
              </p>
              <p>
                <a href="" class="btn btn-light hover fs-5 fw-semibold slide-left rounded-pill p-3 border-2 mt-4" routerLink="/services">{{ 'hero.ourservices' | translate }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


