import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Aboutus } from '../../models/aboutus';

import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {


  aboutusList$!: Observable<Aboutus[]>;
  aboutus:Aboutus = { id:'', order:'', description:{ar:'', en:''} , subheading:{ar:'', en:''} }
  private collectionName = 'aboutus';
  constructor( private firestoreService: FirestoreService<Aboutus>  ) { }

  ngOnInit(): void {
    this.getAboutus();
  }


  getAboutus() {

   this.aboutusList$ = this.firestoreService.getAll(this.collectionName);
  }

  saveAboutus() {

    this.firestoreService.save(this.collectionName , this.aboutus , this.aboutus.id)
    this.clearForm();
  }

  editAboutus(link : Aboutus) {

    this.aboutus = {...link};
    // console.log(this.aboutus);

  }

  clearForm() {
    this.aboutus =  { id:'', order:'', description:{ar:'', en:''} , subheading:{ar:'', en:''} }// Clear the form
  }
  deleteAboutus(id : string) {

    this.firestoreService.delete(id , this.collectionName)

  }
}
