import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Service } from 'src/app/admin/models/service';
import { HomeServiceService } from 'src/app/admin/services/homeService.service';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit, OnDestroy {
  currentLang!: string;
  ServiceLink$!: Observable<Service[]>;
  langChangeSubscription!: Subscription;

  constructor(private homeService: HomeServiceService, private translate: TranslateService) {}

  ngOnInit(): void {
    // Get the services from Firestore
    this.ServiceLink$ = this.homeService.getAllServices();

    // Set initial language
    this.currentLang = this.translate.currentLang || 'ar';

    // Subscribe to language change events
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the language change event when the component is destroyed
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
