// app-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutsUspageComponent } from 'src/app/AboutUs-page/aboutsUspage/aboutsUspage.component';
import { ContactComponent } from 'src/ContactUs-page/contact/contact.component';
import { HomeComponent } from 'src/Home-page/components/home/home.component';
import { ServicepageComponent } from 'src/Service-page/servicepage/servicepage.component';
import { AdminRoutingModule } from './admin/admin-routing.module';

const routes: Routes = [
  { path: ':lang/home', component: HomeComponent },
  { path: ':lang/about-us', component: AboutsUspageComponent },
  { path: ':lang/services', component: ServicepageComponent },
  { path: ':lang/contact', component: ContactComponent },

  // Lazy loading the AdminModule
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },

  { path: '', redirectTo: 'ar/home', pathMatch: 'full' }, // default to Arabic
  { path: '**', redirectTo: 'ar/home' } // fallback route


];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    AdminRoutingModule  // Importing the AdminModule for lazy loading
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
