import { Component, Renderer2 } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent {

  formSubmissions!:Observable<any[]>

  constructor(private renderer: Renderer2 , private firestore: AngularFirestore) { }


ngOnInit(){
    // Listen for changes in real-time
    this.formSubmissions = this.firestore.collection('formSubmissions', ref => ref.orderBy('timestamp', 'desc')).valueChanges();
  this.renderer.setStyle( document.body , 'background-color',' rgb(155, 171, 146)');

 }


}
