import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ServicePage } from 'src/app/admin/models/servicePage';
import { ServicePageService } from 'src/app/admin/services/service-page.service';

@Component({
  selector: 'app-servicepage',
  templateUrl: './servicepage.component.html',
  styleUrls: ['./servicepage.component.css']
})
export class ServicepageComponent implements OnInit {

  currentLang!:string;
  langChange$!:Subscription;
  servicesList$!: Observable<ServicePage[]>;

// inject the servicePageService in the constructor to fetch the data from Firestore collection
  constructor( private servicePageService:ServicePageService , private translate:TranslateService) { }

  ngOnInit() {
    this.servicesList$ = this.servicePageService.getAllServices();
    this.currentLang = this.translate.currentLang||'ar';

    this.langChange$= this.translate.onLangChange.subscribe((event)=>{
      this.currentLang = event.lang;
    })
  }

}
