import { Component, OnInit } from '@angular/core';
import { Service } from '../../models/service';
import { Observable, Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {


  private collectionName = 'services';
  serviceList$!: Observable<Service[]>;
  service: Service = { id: '', order: '', description: { ar: '', en: '' }, subheading: { ar: '', en: '' }, icon: '' };
  paginatedServices: Service[] = [];
  totalServices: number = 0;
  pageSize: number = 3;
  currentPage: number = 0;
  totalPages: number = 0;
  private serviceListSubscription!: Subscription;

  constructor(private firestoreServices: FirestoreService<Service>) {}

  ngOnInit(): void {
    this.getServices();
  }

  ngOnDestroy(): void {
    // Unsubscribe from observable to avoid memory leaks
    if (this.serviceListSubscription) {
      this.serviceListSubscription.unsubscribe();
    }
  }

  getServices() {
    this.serviceList$ = this.firestoreServices.getAll(this.collectionName, 'order', 'asc');
    this.serviceList$.subscribe((services: Service[]) => {
      this.totalServices = services.length;
      this.totalPages = Math.ceil(this.totalServices / this.pageSize);
      this.paginateServices(services);
    });
  }

  paginateServices(services: Service[]) {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedServices = services.slice(startIndex, endIndex);
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.getServices(); // Fetch and paginate services
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.getServices(); // Fetch and paginate services
    }
  }

  saveService() {
    this.firestoreServices.save(this.collectionName, this.service, this.service.id);
    this.clearForm();
  }

  editService(service: Service) {
    this.service = { ...service };
  }

  deleteService(id: string) {
    this.firestoreServices.delete(id, this.collectionName);
  }

  clearForm() {
    this.service = { id: '', order: '', description: { ar: '', en: '' }, subheading: { ar: '', en: '' }, icon: '' };
  }
}
