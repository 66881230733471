
<div class="dropdown text-light mt-1">
  <!-- Small button with dropdown -->
  <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
    <!-- Image size reduced for the small button -->
    <img src="{{'language.language' | translate}}" class="m-1 img-fluid" style="width: 16px; height: 16px;" alt="">
  </button>

  <!-- Small dropdown menu -->
  <ul class="dropdown-menu dropdown-menu-sm p-1" aria-labelledby="dropdownMenu2" style="min-width: 150px;">
    <!-- Smaller list items -->
    <li>
      <a class="dropdown-item p-2" (click)="switchLanguage('en')">
        <img src="{{'language.english.img' | translate}}" class="img-fluid" style="width: 16px; height: 16px;" alt=""> {{'language.english.lang' | translate}}
      </a>
    </li>
    <li>
      <a class="dropdown-item p-2" aria-current="true" (click)="switchLanguage('ar')">
        <img src="{{'language.arabic.img' | translate}}" class="img-fluid" style="width: 16px; height: 16px;" alt=""> {{'language.arabic.lang' | translate}}
      </a>
    </li>
  </ul>
</div>





<!-- <div class="collapse" id="collapseExample">


</div>
<button class="btn btn-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
  {{'language.language' | translate}}
</button> -->


<!-- <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
  <label class="btn btn-outline-light"  (click)="switchLanguage('en') " for="btnradio1">  {{'language.english' | translate}}</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
  <label class="btn btn-outline-light"  (click)="switchLanguage('ar') " for="btnradio1">  {{'language.arabic' | translate}}</label>
</div> -->
