import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Aboutus } from 'src/app/admin/models/aboutus';
import { AboutusService } from 'src/app/admin/services/aboutus.service';
import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  currentLang!:string;
  langChange$!:Subscription;
  // Observable for Aboutus data from Firestore collection
  aboutus$!:Observable<Aboutus[]>;
  constructor( private fireStore:AboutusService , private translate:TranslateService) { }

  ngOnInit() {
    this.aboutus$ = this.fireStore.getAllAboutus();
    this.currentLang = this.translate.currentLang||'ar';

    this.langChange$= this.translate.onLangChange.subscribe((event)=>{
      this.currentLang = event.lang;
    })
  }

}
