import { Component, OnInit , AfterViewInit, OnDestroy } from '@angular/core';

import { HeroService } from 'src/app/admin/services/hero.service';
import { Observable, Subscription } from 'rxjs';
import { Hero } from 'src/app/admin/models/hero';
import { TranslateService } from '@ngx-translate/core';

declare var Flickity: any;

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements AfterViewInit {

  currentLang!:string;
  langChangeSubscription$!:Subscription;

  // Flickity instance to be used for carousel
  private flickityInstance: any;
  hero$!:Observable<Hero[]>;
  constructor( private heroService:HeroService , private translate :TranslateService ) { }

  ngOnInit(): void {
    // Fetch heroes
    this.hero$ = this.heroService.getHero();


// Set initial language

this.currentLang = this.translate.currentLang|| 'ar';

// Subscribe to language change events
this.langChangeSubscription$= this.translate.onLangChange.subscribe( (event)=> {
  this.currentLang = event.lang;
})

  }

  ngAfterViewInit(): void {
    // Initialize Flickity after a short delay to allow *ngFor to finish rendering
    this.hero$.subscribe((heroes) => {
      setTimeout(() => {
        this.reinitializeFlickity(heroes);
      }, 0); // You can adjust the timeout duration if needed
    });
  }


  reinitializeFlickity(heroes: Hero[]) {
    // Check if Flickity has been initialized already and destroy it
    if (this.flickityInstance) {
      this.flickityInstance.destroy(); // Destroy existing Flickity instance to avoid duplication
    }

    setTimeout(() => {
      this.initializeFlickity(); // Reinitialize Flickity after items have been updated/rendered
    }, 0);

  }

  initializeFlickity(){

      this.flickityInstance = new Flickity('.carouselx',{} )




  }



}
