export const environment = {
  production: false,
  apiBaseURL: 'http://localhost:3000',
  sendGridAPI: 'https://api.sendgrid.com/v3/mail/send',
  sendGridAPIKey: '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',
  firebaseConfig: {
    apiKey: "AIzaSyATkYcW5ljk59lDjbfeS-6b7R91HgjRqHw",
    authDomain: "quadrom-5b684.firebaseapp.com",
    projectId: 'quadrom-5b684',
    storageBucket: 'quadrom-5b684.appspot.com',
    messagingSenderId: '611518922112',
    appId: '1:611518922112:web:7ccdf27744ed002cb25565',
    measurementId: "G-33D11463TJ"
  }

};

