<div class="container mt-2">
  <div class="row mt-5 pt-5 align-items-start">
    <div class="col-lg-4 col-md-12 col-sm-12">
      <div class="col-lg-7">
        <img src="/assets/images/whitelogo.webp" style="height: 80px; width: 80px;" alt="">
        <h6>
          <p class="mt-5 lh-base text-light">{{'footer.title' | translate}}</p>
        </h6>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12">
      <div class="row mt-5 pt-3">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4 class="text-light">{{'footer.support' | translate}}</h4>
          <ul class="link list-unstyled text-light">
            <li class="mt-4">
              <p class="text-light fs-6 fw-semibold">{{'footer.jeddahOffice.title' | translate}}</p>
              <p class="text-light fs-6">{{'footer.jeddahOffice.address' | translate}}</p>
              <p class="text-light fs-6">{{'footer.jeddahOffice.phone' | translate}}</p>
              <p class="text-light fs-6">{{'footer.jeddahOffice.fax' | translate}}</p>
            </li>

            <li class="mt-4">
              <p class="text-light fs-6">{{'footer.jeddahOffice.unifiedPhone' | translate}}</p>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-12 col-sm-12">
      <div class="row mt-5 pt-3">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <ul class="link list-unstyled text-light">
            <li>
              <p class="text mt-5 text-light fs-6 fw-semibold">{{'footer.riyadhOffice.title' | translate}}</p>
              <p class="text-light fs-6">{{'footer.riyadhOffice.address' | translate}}</p>
              <p class="text-light fs-6">{{'footer.riyadhOffice.phone' | translate}}</p>
              <p class="text-light fs-6">{{'footer.riyadhOffice.fax' | translate}}</p>
            </li>

            <li class="mt-2">
              <p class="text-light fs-6">{{'footer.riyadhOffice.email' | translate}}</p>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>

  <hr class="border-4 border-light">

  <p class="fs-6 m-4 text-center text-light">{{'footer.copyright' | translate}}</p>
</div>
