<div class="container">

  <div class="container mt-5 pt-5 ">
    <div class="row mt-5 pt-5">

      <div class="section-title text-center ">
        <h3 class="text-light fw-light">

          <span class="border bg-orange rounded-pill p-3">{{'services.ourservices' | translate}}</span>
        </h3>

        <p class="text-light fs-2 mt-5">
          {{'services.heading' | translate}}
        </p>
      </div>
    </div>



    <div class="row ">
      <div class="col-lg-4 col-md-12 col-sm-12" *ngFor="let serviceLink of (ServiceLink$ |async) ">
        <div class="card mt-5" style="width: 100%;  background-color: rgba(255, 255, 255, 0.052); ">
          <div class="card-body text-light">
            <span class="card-title fw-bold">
              <!-- <i class="fas fa-project-diagram"></i> -->
              <i class= {{serviceLink.icon}}></i>
              {{ currentLang === 'ar' ? serviceLink.subheading.ar : serviceLink.subheading.en }}

            </span>


            <p class="card-text text-dark mt-3">{{ currentLang === 'ar' ? serviceLink.description.ar : serviceLink.description.en }}</p>

          </div>
        </div>
      </div>

    </div>

  </div>


</div>
