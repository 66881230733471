import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { Service } from '../models/service';

@Injectable({
  providedIn: 'root'
})
export class HomeServiceService {

  private collectionName = 'services';
constructor( private firestoreService : FirestoreService<Service>) { }


getAllServices(){
  return this.firestoreService.getAll(this.collectionName , 'order' , 'asc');
}

}
