import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.user$ = afAuth.authState;
  }

  async login(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.router.navigate(['/admin']);
    } catch (e) {
      console.log('Login error:', e);

      this.router.navigate(['/home']);

    }
  }

  async logOut() {
    try {
      await this.afAuth.signOut();
      this.router.navigate(['/login']);
    } catch (e) {
      console.error('Logout error:', e);
    }
  }

  isLoggedIn(): Observable<any> {
    return this.afAuth.authState;
  }
}
