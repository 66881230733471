import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirestoreService } from 'src/app/core/firestore.service';
import { AboutusPage } from '../models/aboutusPage';

@Component({
  selector: 'app-aboutus-management',
  templateUrl: './aboutus-management.component.html',
  styleUrls: ['./aboutus-management.component.css']
})
export class AboutusManagementComponent {

  aboutusPage$!:Observable<AboutusPage[]>;
  aboutusPage: AboutusPage = { id:'',order:'', description:'', vision:'', mission:'', founder:''}
  private collectionName = 'aboutusPage';

  constructor( private firestore:FirestoreService<AboutusPage>) { }

  ngOnInit(): void {
    this.getAllAboutusPages();
  }

getAllAboutusPages() {

  this.aboutusPage$ = this.firestore.getAll(this.collectionName);
}

editAboutUsPage(item: AboutusPage){
  this.aboutusPage = {...item};
}

deleteAboutUsPage(id: string){
  this.firestore.delete(id, this.collectionName)
}

saveAboutUsPage(){
  this.firestore.save(this.collectionName, this.aboutusPage, this.aboutusPage.id);
  this.clearForm();

}

clearForm(){
  this.aboutusPage = { id:'',order:'', description:'', vision:'', mission:'', founder:''};
}

}



