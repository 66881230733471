import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AboutusPage } from 'src/app/admin/models/aboutusPage';
import { AboutusService } from 'src/app/admin/services/aboutus.service';
import { AboutusPageService } from 'src/app/admin/services/aboutusPage.service';

@Component({
  selector: 'app-aboutsUspage',
  templateUrl: './aboutsUspage.component.html',
  styleUrls: ['./aboutsUspage.component.css']
})
export class AboutsUspageComponent implements OnInit {

  aboutusPage$!:Observable<AboutusPage[]>;
  constructor(private aboutusService: AboutusPageService) {


   }

  ngOnInit() {

    this.aboutusPage$ = this.aboutusService.getAboutusPage();
  }




}
