import { Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ServicePage } from '../models/servicePage';
import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-services-management',
  templateUrl: './services-management.component.html',
  styleUrls: ['./services-management.component.css']
})
export class ServicesManagementComponent {

  paginatedServices: ServicePage[] = [];
  fullServiceList: ServicePage[] = []; // Store full list of services for pagination
  totalServices: number = 0;
  pageSize: number = 2;
  currentPage: number = 0;
  totalPages: number = 0;
  private servicePageSubscription!: Subscription;

  serviceList$!: Observable<ServicePage[]>;
  private collectionName = 'servicePage';
  serviceForm: ServicePage = {
    id: '',
    order: '',
    imgUrl: '',
    mainPoint: { ar: '', en: '' },
    subpoints: [{ ar: '', en: '' }, { ar: '', en: '' }, { ar: '', en: '' }, { ar: '', en: '' }],
    icon: ''
  };

  constructor(private firestoreSevices: FirestoreService<ServicePage>) { }

  ngOnInit(): void {
    this.getService();
  }

  ngOnDestroy(): void {
    if (this.servicePageSubscription) {
      this.servicePageSubscription.unsubscribe();
    }
  }

  getService() {
    this.serviceList$ = this.firestoreSevices.getAll(this.collectionName);
    this.servicePageSubscription = this.serviceList$.subscribe(services => {
      this.fullServiceList = services;
      this.paginateServices(this.fullServiceList); // Trigger pagination on data load
    });
  }

  paginateServices(services: ServicePage[]) {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedServices = services.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(services.length / this.pageSize);
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.paginateServices(this.fullServiceList); // Use full service list for pagination
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.paginateServices(this.fullServiceList); // Use full service list for pagination
    }
  }

  saveService() {
    this.firestoreSevices.save(this.collectionName, this.serviceForm, this.serviceForm.id);
    this.clearForm();
  }

  clearForm() {
    this.serviceForm = {
      id: '',
      order: '',
      imgUrl: '',
      mainPoint: { ar: '', en: '' },
      subpoints: [{ ar: '', en: '' }, { ar: '', en: '' }, { ar: '', en: '' }, { ar: '', en: '' }],
      icon: ''
    };
  }

  editService(service: ServicePage) {
    this.serviceForm = { ...service };
  }

  deleteService(id: string) {
    this.firestoreSevices.delete(id, this.collectionName);
  }
}
