    <!-- Sidebar -->

    <nav id="sidebar" class="col-md-12 col-sm-12 col-lg-12  bg-success border-2 sidebar " style="min-height: 100vh;">
      <div class="">
        <div>
          <img src="assets/images/logo.webp" alt="Logo" class="img-fluid" style="width: 20rem; height: auto;" />
          <h4 class="text-center">QuadroM</h4>
          <hr>
          <ul class="nav flex-column text-center"> <!-- Add flex-column here -->

            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./inbox">
                <i class="bi bi-list"></i>
                <button type="button" class="btn btn-outline-success position-relative border border-2 rounded-3 ">
                <h5 class="text-dark m-1 "> Inbox</h5>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {{ unreadCount > 0 ? unreadCount : '0' }}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </button>
              </a>
            </li>
            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./nav-management">
                <span class="text-dark "> Manage Navbar</span>
              </a>
            </li>

            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./hero-management">
                <i class="bi bi-list"></i>
                <span class="text-dark "> Manage Hero</span>
              </a>
            </li>

                <li class="nav-item p-2">
              <a class="nav-link" routerLink="./aboutus">
                <i class="bi bi-list"></i>
                <span class="text-dark"> Manage About us</span>
              </a>
            </li>


            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./services">
                <span class="text-dark"> Manage Services</span>
              </a>
            </li>



            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./our-customers">
                <span class="text-dark"> Manage Customers Logos</span>
              </a>
            </li>

            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./services-management">
                <span class="text-dark"> Manage Services Page</span>
              </a>
            </li>



            <li class="nav-item p-2">
              <a class="nav-link" routerLink="./aboutus-management">
                <span class="text-dark "> Manage About us Page</span>
              </a>
            </li>
          </ul>
        </div>


            <app-logout></app-logout>
          </div>


    </nav>

