<div class="container">
  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="saveAboutus()">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="HeroOrder" class="form-label">Order:</label>
            <input type="text" class="form-control" id="HeroOrder" [(ngModel)]="aboutus.order" name="order" />
            </div>
            <div class="col-md-3 mb-3">
              <label for="HeadingAr" class="form-label">Description (AR):</label>
              <input type="text" class="form-control" id="HeadingAr" [(ngModel)]="aboutus.description.ar" name="headingAr" required />
            </div>
            <div class="col-md-3 mb-3">
              <label for="HeadingEn" class="form-label">Description (EN):</label>
              <input type="text" class="form-control" id="HeadingEn" [(ngModel)]="aboutus.description.en" name="headingEn" required />
            </div>
            <div class="col-md-3 mb-3">
              <label for="SubheadingAr" class="form-label">Subheading (AR):</label>
              <input type="text" class="form-control" id="SubheadingAr" [(ngModel)]="aboutus.subheading.ar" name="subheadingAr" required />
            </div>



          <div class="col-md-3 mb-3">
            <label for="SubheadingEn" class="form-label">Subheading (EN):</label>
            <input type="text" class="form-control" id="SubheadingEn" [(ngModel)]="aboutus.subheading.en" name="subheadingEn" required />
          </div>

          <div class="col-md-3 mb-3">
            <label for="heroId" class="form-label">Id:</label>
            <input type="text" class="form-control" id="heroId" [(ngModel)]="aboutus.id" name="id" readonly />
          </div>
        </div>

        <button type="submit" class="btn btn-success">Add</button>
      </form>
    </div>
  </div>
</div>

<table class="table caption-top">
  <caption>About Us section</caption>
  <thead>
    <tr>
      <th scope="col">Order</th>
      <th scope="col">Description (AR)</th>
      <th scope="col">Description (EN)</th>
      <th scope="col">Subheading (AR) </th>
      <th scope="col">Subheading (EN)</th>

      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let aboutusLink of aboutusList$ | async">
      <td>{{ aboutusLink.order }}</td>
      <td>{{ aboutusLink.description.ar }}</td>
      <td>{{ aboutusLink.description.en }}</td>
      <td>{{ aboutusLink.subheading.ar }}</td>
      <td>{{ aboutusLink.subheading.en }}</td>


      <td>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-sm btn-warning" (click)="editAboutus(aboutusLink)"><i class="fa-solid fa-pen" style="color: #fdfdfd;"></i></button>
        <button type="button" class="btn btn-sm  btn-danger" (click)="deleteAboutus(aboutusLink.id)"><i class="fa-solid fa-trash" style="color: #fafafa;"></i></button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
