import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  public isLoading = true;
  private routerSubscription!: Subscription;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Manually show the spinner on initial load
    // Hide the spinner after a short delay to simulate initial load
    setTimeout(() => {
      this.isLoading = false;
    }, 900); // Adjust the delay as needed


  }

  onImageLoad() {
    this.isLoading = false; // Hide spinner when the image loads
  }
}
