<div class="container-fluid" style="min-height: 100vh;">

  <div class="row">

<div class=" col-lg-2 col-md-3 col-sm-3 p-0">

  <app-sidebar class="sidebar"></app-sidebar>
</div>

<div class=" col-lg-8 col-md-8 col-sm-8 p-0 ms-5">
  <main>
    <h4 class="mt-3"> hi admin</h4>
  <router-outlet></router-outlet>
</main>
</div>

</div>
</div>
