import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/Home-page/components/shared-Components/translate-button/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Mandurah-Website';

  constructor(private translateservice: TranslationService) { }


  ngOnInit() {
    // Ensure correct language and direction on page load
    const lang = window.location.pathname.split('/')[1] || 'ar';
    this.translateservice.setLanguage(lang);

    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
  }
}
