import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Nav } from '../models/nav';
import { Observable } from 'rxjs';
import { NavServiceService } from '../services/NavService.service';
import { FirestoreService } from 'src/app/core/firestore.service';

@Component({
  selector: 'app-nav-management',
  templateUrl: './nav-management.component.html',
  styleUrls: ['./nav-management.component.css']
})

export class NavManagementComponent implements OnInit , OnDestroy {
  navLinks$!: Observable<Nav[]>; // List of nav links
  navLink: Nav = { title: { ar:'', en:''}, url: '', order: '0' , id:'' }; // For the form
  private collectionName = 'navbar';

  constructor(private firestoreService: FirestoreService<Nav> , private renderer:Renderer2 ) {}

  ngOnInit(): void {

    this.renderer.setStyle( document.body , 'background-color',' rgb(155, 171, 146)');
    this.getAllLinks();
  }

  saveNavLink() {
    this.firestoreService.save(this.collectionName , this.navLink , this.navLink.id)
      .then(() => {
        console.log(this.navLink.id ? "Updated" : "Added");
        this.clearForm();
      })
      .catch(error => console.error('Operation failed:', error));
  }

getAllLinks(){

  this.navLinks$ = this.firestoreService.getAll(this.collectionName);

}

  editNavLink(link: Nav) {
    this.navLink = { ...link }; // Fill the form with the selected link
  }

  deleteNavLink(id: string) {
    this.firestoreService.delete(id , this.collectionName)
    .then(() => console.log('Deleted'))
    .catch(error => console.error('Delete operation failed:', error));
  }

  clearForm() {
    this.navLink = { title: { ar:'', en:''}, url: '', order: '0' , id:'' }; // Clear the form
  }

  ngOnDestroy() {
    // Reset the background color when leaving the admin page
    this.renderer.setStyle(document.body, 'background-color', '');
  }
}
