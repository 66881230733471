<div class="container">
  <div class="row justify-content-start align-items-start">
    <div class="col-lg-12">
      <form (ngSubmit)="SaveCustomersImg()">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="logoOrder" class="form-label">Order:</label>
            <input type="text" class="form-control" id="logoOrder" [(ngModel)]="customersImg.order" name="order" />
          </div>

          <div class="col-md-3 mb-3">
            <label for="Image" class="form-label">Image:</label>
            <input type="file" class="form-control" id="Image" (change)="onFileSelected($event)" />
            <button type="button" class="btn btn-primary mt-2" (click)="uploadImage()">Upload Image</button>
          </div>

          <div class="col-md-3 mb-3">
            <label for="logoId" class="form-label">Id:</label>
            <input type="text" class="form-control" id="logoId" [(ngModel)]="customersImg.id" name="id" readonly />
          </div>
        </div>

        <button type="submit" class="btn btn-success" [disabled]="!selectedFile && !customersImg.id">Add / Update</button>
      </form>
    </div>
  </div>
</div>

<table class="table caption-top">
  <caption>Clients Logos</caption>
  <thead>
    <tr>
      <th scope="col">Order</th>
      <th scope="col">Image</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let logo of paginatedLogos">
      <td>{{ logo.order }}</td>
      <td>
        <img [src]="logo.imageUrl" alt="Client logo" style="width: 100px; height: auto;" *ngIf="logo.imageUrl" />
      </td>
      <td>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-sm btn-warning" (click)="editLogo(logo)"><i class="fa-solid fa-pen" style="color: #fdfdfd;"></i></button>
          <button type="button" class="btn btn-sm  btn-danger" (click)="deleteLogo(logo.id)"><i class="fa-solid fa-trash" style="color: #fafafa;"></i></button>

        </div>
      </td>
    </tr>
  </tbody>
</table>
<div class="row justify-content-center">

  <div class="col-md-4">
<!-- Pagination Controls -->
<div class="pagination-controls">
  <button class="btn btn-sm btn-light" (click)="previousPage()" [disabled]="currentPage === 0"><</button>
  <span class="mx-5">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
  <button class="btn btn-sm btn-light" (click)="nextPage()" [disabled]="currentPage >= totalPages - 1"> ></button>
</div>
  </div>
</div>
