<div class="container" > <!-- Ensure the container takes full viewport height -->

  <div class="row mt-5">
    <div class="col-lg-12 mt-5">

    </div>
    <div class="col-lg-12 mt-5">

    </div>
    <div class="col-lg-12 mt-5">

    </div>

  </div>
  <div class="row justify-content-center "> <!-- Align items center -->

    <div class="col-lg-8">
      <div class="card mb-3 border rounded-4 " style="height:28rem">
        <div class="row no-gutters">
          <div class="col-md-6 ">
            <img src="assets/images/comp.jpg" class="img-fluid p-5 " style="height: 28rem;" alt="...">
          </div>
          <div class="col-md-5 d-flex align-items-start"> <!-- Center the form vertically inside this column -->
            <div class="card-body">

              <img src="assets/images/logo.webp" class="img-fluid rounded-start ms-1" style="height: 5rem;" alt="...">
              <p class="card-title fw-lighter text-center mt-1">Welcome to QuadroM Admin Page</p>
              <hr>
              <form (ngSubmit)="onSubmit()" class="mt-2">

                <div class="form-group ">
                  <label for="email" class=" mt-2">Email</label>
                  <input type="email" id="email" [(ngModel)]="email" name="email" class="form-control mt-3">
                </div>

                <div class="form-group">
                  <label for="password" class="mt-3">Password</label>
                  <input type="password" id="password" [(ngModel)]="password" name="password" class="form-control mt-3">
                </div>
                <button type="submit" class="btn btn-success mt-4 mb-5">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
