<div class="container mt-5 pt-5">
  <div class="row justify-content-center pt-3 mt-5">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <h3 class="text-center text-light fw-normal">
        <span class="border bg-orange  rounded-pill p-3">{{ 'aboutus.aboutus' | translate }}</span>
      </h3>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-lg-6 ">
      <h2 class="text-center text-light p-2">{{ 'aboutus.heading' | translate }}</h2>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <p class="text-center  text-light fw-normal p-2">{{ 'aboutus.heading' | translate }}</p>
    </div>
  </div>

  <div class="container-fluid ">


    <div class="row ">

      <div class="col-lg-4 " *ngFor=" let aboutus of aboutus$ |async">
        <div class="card mt-5" style="width: 100%; background-color: rgba(255, 255, 255, 0.052); ">
          <div class="card-body text-light text-center">
            <span class="card-title fw-bold fs-2">
              {{ this.currentLang === 'ar' ? aboutus.subheading.ar : aboutus.subheading.en}}

            </span>
            <p class="card-text mt-3 fs-6 fw-normal text-center">

              {{ this.currentLang === 'ar' ? aboutus.description.ar : aboutus.description.en}}
            </p>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
