import { Injectable, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { ServicePage } from '../models/servicePage';

@Injectable({
  providedIn: 'root'
})
export class ServicePageService {

  private collectionName = 'servicePage';
constructor( private fireStoreService:FirestoreService<ServicePage> ) { }



getAllServices(){

  return this.fireStoreService.getAll(this.collectionName, 'order' ,'asc') ;
}
}
